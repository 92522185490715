
<template>
<div>
  <div class="row">
    <div class="col-12">
      <h5 class="mb-3">Transactions list</h5>
      <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-8">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex mr-2 align-items-center">
                    <b-form-select v-model="itemsPerPage" size="sm" :options="pageOptions"></b-form-select>
                  </label>
                  <b-dropdown variant="light" size="sm" left>
                    <template slot="button-content">
                        <i class="mdi mdi-filter-variant"></i>
                    </template>
                    <b-dropdown-item-button @click="selectAllRows()"><i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'DeSelect All' : 'Select All' }}</b-dropdown-item-button>
                    <b-dropdown-item-button @click="statusUpdate()" ><i class="mdi mdi-cash-refund"></i> Update Status</b-dropdown-item-button>
                    <b-dropdown-item-button @click="deleteItems()" ><i class="mdi mdi-delete-forever-outline"></i> Delete</b-dropdown-item-button>
                  </b-dropdown>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="">
                  <label class="d-block">
                    <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control form-control-sm"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class=""
                ref="selectableTable" :items="pageData.data" :fields="columns" responsive="sm">
                <template v-slot:cell(check)="data">
                    <div class="custom-control custom-checkbox text-center">
                        <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkorder${data.item.id}`" />
                        <label class="custom-control-label" :for="`checkorder${data.item.id}`">{{data.item.id}}</label>
                    </div>
                </template>
                <template v-slot:cell(card)="data">
                    <div class="d-inline-block cursor-pointer align-middle">
                        <p class="text-dark mb-1 font-sm">{{ data.item.account_name }}</p>
                        <p class="text-dark mb-1 font-sm">{{ data.item.account_number }}</p>
                        <p class="text-dark mb-1 font-sm">{{ data.item.bank_name }}</p>
                        <p class="mb-0 font-sm"><router-link :to="`/users/${data.item.worker_id}`">view worker</router-link></p>
                    </div>
                </template>
                <template v-slot:cell(status)="data">
                    <div class="d-inline-block cursor-pointer align-middle">
                        <p class="text-dark mb-1 font-sm">{{ data.item.status }}</p>
                    </div>
                </template>
                <template v-slot:cell(amount)="data">
                    <div class="d-inline-block cursor-pointer align-middle">
                        <p class="text-dark mb-1 font-sm">${{ data.item.amount }}</p>
                        <p class="text-dark mb-1 font-sm">{{ data.item.exchange_rate }}</p>
                    </div>
                </template>
                <template v-slot:cell(date)="data">
                    <span class="cursor-pointer">{{data.item.created_at | date_ago}} </span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="paging_simple_numbers float-right">
                  <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                    :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <b-modal size="md" title="Transaction Request"
      body-bg-variant="light" body-class="p-3"
        hide-footer v-model="statusObj.modal">
      <div>
        <b-alert show dismissible fade variant="warning">
          <h4 class="alert-heading">Be sure!</h4>
          <p> You have selected {{statusObj.count}} transaction{{statusObj.count > 1 ? 's':''}} to be updated</p>
        </b-alert>
        <div class="form-group mb-3">
          <label class="">Select Status</label>
          <select class="custom-select" v-model="statusObj.action" placeholder="--select status--">
              <option disabled :value="null" key="-1">-- choose status --</option>
              <option :value="status.action" v-for="(status, sIndex) in statusObj.list" :key="sIndex">{{status.label}}</option>
          </select>
        </div>
          <div class="form-group text-right mt-3">
            <button class="btn btn-primary" @click.prevent="updateStatus()"  :disabled="statusObj.action ? false : true" type="button">Update</button>
          </div>
      </div>
  </b-modal>
</div>
</template>

<script>
import IsLoading from "@/components/IsLoading.vue"
import _ from 'lodash';

export default {
  components:{
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      searchQuery: "",
      pageData: {
        data:[]
      },
      pageOptions: [10, 25, 50, 100],
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
            key: "Card",
            label: "Card details",
            sortable: true
          },
          {
              key: "status",
              label: "Status",
              sortable: true
          },
          {
            key: "Amount",
            sortable: true
          },
          {
              key: "date",
              label: "Date",
              sortable: true
          },

      ],
      statusObj:{
        modal: false,
        count: 0,
        action: null,
        list: [
          { action: 'paid', label: 'Mark As Paid'},
          { action: 'pending', label: 'Mark Pending'},
          { action: 'cancelled', label: 'Cancel'},
        ] 
      }
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchTransactions(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchTransactions(1, val)
      }
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.transactions.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    statusUpdate(){
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      this.statusObj.count = this.selected.length;
      this.statusObj.modal = true;
    },
    updateStatus() {
      const formData = new FormData()
      formData.append('data', JSON.stringify({items: this.selected, action: this.statusObj.action }))
      this.$store.dispatch("changeLoaderValue",true)
      this.$http.post('/transactions/update',formData)
      .then(response => {
        this.$store.dispatch("changeLoaderValue",false)
        if(response.data.success){
          this.statusObj.modal = false;
          this.fetchTransactions()
        }
      })
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchTransactions()
    }, 2000),
    fetchTransactions(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$http.get(`/transactions?${new URLSearchParams(payload).toString()}`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.pageData = response.data.data;
        }
      })
    },
    deleteItems() {
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      let text = `You are about to delete ${this.selected.length} transaction${this.selected.length > 1 ? 's':'' }`;
      Swal.fire({
        title: "Are you sure?",
        text: text,
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          const formData = new FormData()
          formData.append('data', JSON.stringify({items: this.selected}))
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post('/transactions/delete', formData)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.fetchTransactions()
            }
          })
        }
      });
    },
  },
  mounted() {
    this.fetchTransactions()
  },
}

</script>

<style lang="scss">
.font-sm{
  font-size:13px;
}
</style>
